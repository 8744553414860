* {
  outline: none !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html,
body {
  height: 100%;
  background-color: #eeeeee;
}
body {
  margin: 0;
  overflow: hidden;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
}
p {
  margin: 0;
  padding: 0;
}

// Clarity Overrides:
.modal-dialog .modal-content {
  background-color: #faf7fa;
}
.btn-primary .btn,
.btn.btn-primary {
  border-color: #9e6caf;
  background-color: #9e6caf;
  color: #fff;
}
.btn-primary .btn:hover,
.btn.btn-primary:hover,
.btn:hover {
  background-color: #c09cc5;
  color: #fff;
}
.btn:active,
.btn.btn-primary:active,
.btn.btn-outline:active {
  box-shadow: unset;
}
.btn-info-outline .btn,
.btn-outline .btn,
.btn.btn-info-outline,
.btn.btn-outline,
.btn.btn-outline .btn,
.btn.btn-outline-info {
  border-color: #9e6caf;
  background-color: transparent;
  color: #9e6caf;
}
.btn-info-outline .btn:hover,
.btn-outline .btn:hover,
.btn.btn-info-outline:hover,
.btn.btn-outline .btn:hover,
.btn.btn-outline-info:hover,
.btn.btn-outline:hover {
  background-color: #c09cc5;
  color: #fff;
}
// END
.capsule {
  border-radius: 10vh;
  color: rgba(var(--text-color), 1);
  background-color: #ccc;
  cursor: grab;
  display: block;
  font-family: 'muli-bold', sans-serif;
  font-size: 0.8vw;
  margin: 0.5vh 0.2vw;
  overflow: hidden;
  padding: 0.3vh 0.5vw;
  text-transform: uppercase;
  flex: none;
}
.capsule:hover {
  background-color: #c09cc5;
  color: var(--button-text-color);
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 10vh;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.center-text {
  text-align: center;
  color: var(--text-color);
}
.flex {
  display: flex;
}
.flex-grow {
  flex: flex-grow;
}
.sidebar {
  position: absolute;
  z-index: 100;
  width: auto;
  height: auto;
  pointer-events: none;
  * {
    pointer-events: all;
    cursor: default;
  }

  &.left-sidebar {
    z-index: 101;
  }
}

.left-sidebar {
  top: 0;
  left: 0;
}
.right-sidebar {
  top: 0;
  right: 0;
}
.bottom-sidebar {
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-bottom: 40px;
  pointer-events: none;
  & > * {
    pointer-events: initial;
  }
  app-explore-bar {
    flex: 1;
  }
}
.statsbar {
  bottom: 0;
  width: 100vw;
  z-index: 101;
}
.space-between {
  justify-content: space-between;
}
.tool-icon {
  width: 1.2vw;
  height: 1.2vw;
}
.toolbox,
.right-header,
.left-header,
.show-bar,
.rank-bar,
.compass,
.insights-panel-component {
  margin: 5px 10px 10px 10px;
  padding: 0;
  width: auto;
  height: 56px;
  &::after {
    content: '';
    clear: both;
    display: block;
  }
  pointer-events: none;
  * {
    pointer-events: all;
    cursor: default;
  }
}
.left-header,
.right-header,
.show-bar,
.rank-bar {
  height: 56px;
}
.toolbox-heading {
  font-size: 0.8vw;
  text-transform: uppercase;
  font-family: 'muli-bold', sans-serif;
  font-weight: 600;
  border-bottom: 1px solid var(--button-border-color);
  margin: 0;
  margin-bottom: 0.6vh;
  padding: 0.65vw 0;
  color: var(--text-color);
  // text-align: center;
  padding-left: 0.5vw;
}
.toolbox-btn {
  background: var(--button-background);
  border: 0.1vh solid var(--button-border-color);
  // padding: 1vh 2vh 1vh;
  border-radius: 5px;
  color: var(--button-text-color);
  text-transform: uppercase;
  font-size: 0.75vw; // TODO make all font sizes based on VW NOT VH
  font-family: 'muli-regular', sans-serif;
  display: block;
  text-align: center;
  // max-width: 80vw;
  width: 90%;
  margin: 4px auto;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// -------- Scrollable
.scrollable::-webkit-scrollbar {
  width: 6px;
}
.scrollable::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #dddddd;
  border-right: 3px transparent solid;
  border-top: 3px transparent solid;
  border-bottom: 3px transparent solid;
  background-clip: padding-box;
}
.cdk-virtual-scrollable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

// -------- container for mat-spinner
.loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

// ------- mat-spinner
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--accent-color) !important;
}

// -------- mat-tooltip override
body .mdc-tooltip__surface {
  background-color: var(--mat-select-background-color);
}
.mdc-tooltip {
  white-space: pre-line;
  text-align: center;
  position: relative;
  font-size: 12px;
  padding: 5px 8px;
  margin: 0 !important;

  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid var(--mat-select-background-color);
  }

  &.below {
    overflow: initial;
    // margin-top: 12px !important;
    padding: 0;

    &:after {
      top: -5px;
      right: calc(50% - 6px);
      transform: rotate(0);
    }

    &.home {
      margin-left: 24px !important;
      margin-top: 7px !important;
      &:after {
        right: calc(50% + 6px);
      }
    }

    &.resources {
      right: -6px;
    }
  }

  &.above {
    overflow: initial;
    // margin-bottom: 12px !important;
    padding: 0;

    &:after {
      bottom: -5px;
      right: calc(50% - 6px);
      transform: rotate(180deg);
    }

    &.toggle-compass {
      margin-right: -6px !important;
      &:after {
        right: 23px;
      }
    }
  }

  &.right {
    overflow: initial;
    // margin-left: 12px !important;
    padding: 0;

    &:after {
      left: -8px;
      top: 8px;
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    // margin-right: 12px !important;
    padding: 0;

    &:after {
      right: -8px;
      top: 8px;
      transform: rotate(90deg);
    }
  }
}

// -------- mat-tab override
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--primary-color);
}
.mat-mdc-tab-label-container {
  border-bottom: 1px solid rgb(0 0 0 / 50%);
}

.mat-tab-header,
.mat-tab-label-container {
  overflow: visible !important;
}

.mat-tab-label {
  height: auto !important;
  color: var(--primary-color);
  opacity: 1 !important;

  &-active {
    background: #f2f2f2 !important;
  }
}

.mat-ink-bar {
  background: var(--accent-color) !important;
  margin-bottom: -1px;
}

// -------------- mat-form override
.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
.mdc-text-field--filled.mdc-textfield-focused {
  background: rgba(44, 44, 44, 0.04);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--accent-color);
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 8px 16px;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  padding: 3px 20px 0;
}

// -------------- directive app-delayed-hover
.delayed-hover {
  opacity: 0.4;
  transition: opacity 1000ms cubic-bezier(0, 0, 0.2, 1);

  &.hover {
    opacity: 1;
    transition: opacity 280ms cubic-bezier(0, 0, 0.2, 1);
  }
}

// -------------- mat-menu override

.mat-mdc-menu-panel {
  &.wide-mat-menu {
    max-width: none;
  }

  &.small-mat-menu {
    min-width: 48px;
    min-height: 48px;
  }

  &.no-padding .mat-menu-content:not(:empty) {
    padding: 0;
  }

  &.mat-menu-matrix-types-box {
    min-width: auto;
    max-height: none;
    margin-left: -12px;
    margin-bottom: 8px;
  }
}

// --------------- mat-button override
.mat-flat-button {
  &:not([disabled]) {
    // background: #f2f2f2;
    &:hover {
      box-shadow: 1px 1px 2px 1px rgb(64, 64, 64, 0.1);
    }
  }
}

// matrix-type-menu, if opened - change opacity for toolbox-left-primary
body.matrix-type-menu-opened app-toolbox-left-primary {
  opacity: 0.4;
}

//mat-dialog override
mat-dialog-content.mat-dialog-content {
  max-height: 90vh;
  overflow: hidden;
}

mat-dialog-container.mat-dialog-container {
  position: relative;
  border-radius: 10px;
}

mat-selection-list.connections-source-list {
  .mat-list-item,
  .mat-list-option {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;

    &.mat-list-single-selected-option,
    &.mat-list-single-selected-option:hover,
    &.mat-list-single-selected-option:focus {
      background: #f2f2f2;
    }

    .mat-list-item-content,
    .mat-list-item-content-reverse {
      .mat-list-text {
        padding: 0 !important;
        & > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

mat-progress-bar.file-upload-progress-bar {
  &.uploaded {
    .mat-progress-bar-fill::after {
      background-color: #0000006b;
    }
  }
}

.insight-progress-bar.mat-mdc-progress-bar {
  height: 7px;
  border-radius: 3px;
  .mdc-linear-progress__buffer {
    height: 7px;
    .mdc-linear-progress__buffer-bar {
      background-color: #fff;
    }
  }
  .mdc-linear-progress__bar {
    height: 7px;
    .mdc-linear-progress__bar-inner {
      border-top-width: 7px;
      border-color: #33323d;
    }
  }
}

.insight-group-progress-bar.mat-mdc-progress-bar {
  height: 5px;
  border-radius: 3px;
  .mdc-linear-progress__buffer {
    height: 5px;
    .mdc-linear-progress__buffer-bar {
      background-color: #fff;
    }
  }
  .mdc-linear-progress__bar {
    height: 5px;
    .mdc-linear-progress__bar-inner {
      border-top-width: 5px;
      border-color: #33323d;
    }
  }
}

.wide-dialog {
  min-width: 96% !important;
}

.presentation-dialog {
  max-width: calc(100vw - 100px) !important;
  max-height: calc(100vh - 100px) !important;

  .mat-mdc-dialog-actions.mdc-dialog__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;

    button {
      margin: 0;
    }
  }
}

.change-product-name-column-dialog {
  min-width: 600px !important;
  max-width: 600px !important;
}

.mat-dialog-actions {
  flex-direction: row-reverse;
}

// override styles for adaptive-toolbar drag-n-drop in RankBarComponent
app-adaptive-toolbar-item.cdk-drag-preview.rank-bar-added-filter .adaptive-toolbar-item {
  background: #f2f2f2;
  padding: 7px 12px;
  margin: 0 25px 0 0;
  height: 38px;
  justify-content: space-between;
  border-radius: 8px;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;

  .filter-name {
    display: flex;
    line-height: 18px;
    margin: 0;
    align-items: center;

    span {
      display: contents;
      white-space: nowrap;
      margin: 0 15px 0 5px;
      font-size: 13px;
      line-height: 38px;
    }
  }
}

// override styles for adaptive-toolbar drag-n-drop in ShowBarComponent
app-adaptive-toolbar-item.cdk-drag-preview.show-bar-added-filter .adaptive-toolbar-item {
  background: #f2f2f2;
  padding: 0 20px;
  margin: 0 10px 0 0;
  height: 38px;
  justify-content: space-between;
  border-radius: 8px;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;

  .filter-name {
    display: flex;
    line-height: 18px;
    margin: 0;
    align-items: center;

    span {
      display: contents;
      white-space: nowrap;
      margin: 0 15px 0 5px;
      font-size: 13px;
      line-height: 38px;
    }
  }
}

//mat-chips
mat-chip {
  font-weight: normal !important;
  font-size: 13px !important;
  border-radius: 5px !important;

  &:hover::after {
    opacity: 0 !important;
  }
}

// ---------------- Freestyle/GroupBy frame title ---------------
.frameTitle {
  position: absolute;
  z-index: 97;

  display: flex;
  overflow: hidden;

  pointer-events: none;

  * {
    font: 18px/27px 'Roboto', sans-serif;
    color: var(--text-primary-lighter-color);
    position: relative;
  }

  .frameFeatureName {
    margin-right: 9px;
    &::after {
      content: ':';
      position: absolute;
      bottom: 0;
      right: -4px;
    }
  }

  span {
    display: inline-block;
    white-space: nowrap;

    &:empty:before {
      content: attr(placeholder);
      color: #9f99a5;
    }

    &:focus {
      color: currentColor;
    }
  }

  input {
    background-color: transparent;
    border: none;

    &::placeholder {
      color: #9f99a5;
    }
  }

  .frameFeatureValue {
    margin-right: 5px;
  }

  .frameNumImages {
    margin-left: 5px;

    &::before {
      content: '';
      position: absolute;
      top: 4.5px;
      left: -5px;
      width: 0;
      height: 18px;
      border-right: 1px solid var(--primary-color);
    }
  }
}

.chip-formatting-option {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 13px !important;
  min-height: 25px !important;
}

// ---------------- Rank Group Labels ---------------
.rankGroupWrapper {
  opacity: 1; // 0.4;
  visibility: initial;
  // transition: opacity 280ms, visibility 280ms;
  display: block;
  position: absolute;
  left: -1000px;
  top: -1000px;
  z-index: 80;
}

.rankGroupWrapper.init {
  opacity: 0;
}

.groupLabel {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  color: var(--text-primary-lighter-color);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: block;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rankGroupHandlerLine {
  position: absolute;
  background-color: var(--text-primary-lighter-color);
  border-radius: 2px;
  z-index: 79;
}

.groupQuickStats {
  position: absolute;
  z-index: 99;
  height: 0;
  width: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  color: var(--text-primary-lighter-color);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .groupQuickStatsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    width: auto;
    height: auto;
  }

  .field-name,
  .field-value {
    font-size: 12px;
    line-height: 14px;
    max-width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  .field-name {
    font-weight: 300;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  .field-value {
    font-weight: 500;
  }
}

.groupLabelTooltip {
  position: absolute;
  opacity: 0;
  border-radius: 4px;
  min-width: 50px;
  max-width: 300px;
  padding: 8px;
  background: rgba(95, 95, 95, 0.9);
  z-index: 99;
  color: #fff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rankGroupWrapper.hover {
  opacity: 1 !important;
}

.rankGroupWrapper.vertical {
  display: flex;
  flex-direction: column;
  justify-items: center;

  .rankGroupHandlerLine {
    display: block;
    left: calc(50% - 2px);
    top: -6px;
    height: calc(100% + 12px);
    width: 4px;
  }
  .groupLabel {
    left: 0;
    top: 0;
    height: 100%;

    &:not(.parent) {
      writing-mode: vertical-rl;
      padding: 5px 0;

      // &.top {
      //   transform: scale(-1);
      //   box-shadow: 0px -4px 4px 0px #00000040;
      // }
    }

    &.parent {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: break-spaces;
      line-height: 1.2;
    }
  }
  .groupQuickStats {
    top: 0;
    height: 100%;
    flex-direction: row;
  }
  .group-info-icon {
    top: -12px;
    left: 50%;
  }
  .group-quick-stats-icon {
    top: -20px;
  }

  &.left {
    .group-quick-stats-icon {
      left: calc(50% - 15px);
      img {
        transform: rotate(90deg);
      }
      &.active {
        img {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &.right {
    .group-quick-stats-icon {
      left: calc(50% + 15px);
      img {
        transform: rotate(-90deg);
      }
      &.active {
        img {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.rankGroupWrapper.horizontal {
  display: block;

  .rankGroupHandlerLine {
    left: -6px;
    top: calc(50% - 2px);
    height: 4px;
    width: calc(100% + 12px);
  }
  .groupLabel {
    left: 0;
    top: 0;
    width: 100%;
  }
  .groupQuickStats {
    left: 0;
    width: 100%;
  }
  .group-info-icon {
    top: 50%;
    left: -13px;
  }

  .group-quick-stats-icon {
    left: -13px;
  }

  &.bottom {
    .group-quick-stats-icon {
      top: calc(50% + 15px);

      img {
        transform: rotate(0deg);
      }
      &.active {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.top {
    .group-quick-stats-icon {
      top: calc(50% - 15px);

      img {
        transform: rotate(180deg);
      }
      &.active {
        img {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.rankGroupWrapper.hiddenLabel {
  opacity: 0.4;
  .groupLabel {
    display: none;
  }
  .groupQuickStats {
    display: none;
  }
  .group-quick-stats-icon {
    display: none;
  }
}
.rankGroupWrapper.hiddenLabel.hidden {
  opacity: 0;
  visibility: hidden;
  // transition: opacity 100ms, visibility 100ms;
}

// ---------------- end of Rank Group Labels ---------------

.customCursor {
  position: absolute;
  left: -1000px;
  top: -1000px;
  display: none;
  pointer-events: none;
  transform-origin: center center;
  z-index: 9999;
}

.frameInfo,
.groupInfo,
.imageInfo {
  background-color: rgba(196, 196, 196, 0.8);
  position: absolute;
  left: -1000px;
  top: -1000px;
  display: none;
  pointer-events: none;
  z-index: 98;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-family: 'Roboto', sans-serif;
    color: var(--text-primary-lighter-color);
    white-space: nowrap;

    &.title {
      font-size: 20px;
      line-height: 23.5px;
      font-weight: 500;

      &.unknown span {
        color: #9f99a5;
      }
    }
    &.field-name {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    &.field-value {
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }
  }
}

.imageInfoPanel {
  position: absolute;
  z-index: 79;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  pointer-events: none;

  &.right,
  &.bottom {
    justify-content: flex-start;
    .imageInfoPanel-wrapper,
    .field {
      align-items: flex-start;
    }
  }

  .imageInfoPanel-wrapper {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .field {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  p {
    font-family: 'Roboto', sans-serif;
    color: var(--text-primary-lighter-color);
    white-space: nowrap;

    &.title,
    &.field-value {
      font-size: 18px;
      line-height: 1.2;
      font-weight: 500;
    }
    &.field-name {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 300;
    }
  }
}

.group-quick-stats-icon {
  display: flex;
  overflow: hidden;
  width: 14px;
  height: 14px;
  transform: translate(-50%, -50%);
  opacity: 0;

  &.hidden {
    display: none;
  }

  img {
    width: calc(100% - 1px);
    height: auto;
  }

  .inactive-icon {
    display: flex;
  }

  .active-icon {
    display: none;
  }

  &.hover {
    .inactive-icon {
      display: none;
    }

    .active-icon {
      display: flex;
    }
  }
}

.group-quick-stats-icon,
.group-info-icon,
.frame-info-icon,
.frame-publish-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 97;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
  }
}

.group-info-icon,
.frame-info-icon {
  transform: translate(-45%, -48%);
}

.frame-info-icon {
  width: 24px;
  height: 24px;

  img {
    display: flex;

    &:last-child {
      display: none;
    }
  }

  &.locked img {
    &:first-child {
      display: none;
    }
    &:last-child {
      display: flex;
    }
  }

  &.active img {
    transform: scale(1.075);
  }
}

.group-info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  top: 15px;
  opacity: 0;

  .circle {
    width: inherit;
    height: inherit;
    display: flex;
    border: 2px solid #33323d;
    border-radius: 50%;
    background-color: #fff;
  }

  img {
    &:first-of-type {
      display: none;
    }
    &:last-of-type {
      display: none;
    }
  }

  &.locked {
    .circle {
      display: none;
    }
    img {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: flex;
      }
    }
  }

  &.active:not(.locked) {
    .circle {
      display: none;
    }
    img {
      &:first-of-type {
        display: flex;
      }
      &:last-of-type {
        display: none;
      }
    }
  }
}

.frame-publish-icon {
  pointer-events: none;
  width: 24px;
  height: 24px;
  transform: translate(-55%, -48%);

  &.spin img {
    animation: iconSpin ease-in-out 1000ms infinite;
  }

  &.fade-in img {
    animation: iconFadeIn ease 100ms;
  }

  &.fade-out img {
    animation: iconFadeIn ease 100ms reverse;
  }

  img {
    display: none;
    transform: rotate(0deg);
    animation: none;
  }

  &.default img.default {
    display: flex;
  }

  &.hover img.hover {
    display: flex;
  }

  &.success img.success {
    display: flex;
  }

  &.fail img.fail {
    display: flex;
  }

  &.retry img.retry {
    display: flex;
  }
}

.frameInfo {
  z-index: 96;
}

.imageInfo p.rank {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 18px;
  font-weight: 400;
}

.groupInfo-wrapper .calculating-message,
.imageInfo .calculating-message .imageInfoPanel .calculating-message {
  font-size: 16px;
  font-weight: normal;
  padding-top: 10px;
}

@keyframes iconSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes iconFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.imagePercentContainer {
  position: absolute;
  &.hide {
    display: none;
  }
}

.imagePercentOverlay {
  background-color: white;
  border-radius: 5px;
  padding: 2px;
  font-family: 'Roboto', sans-serif;
  color: var(--text-primary-lighter-color);
  font-weight: 500;
  position: absolute;
  transform: translate(-100%, -100%);

  &.hide {
    display: none;
  }
}

// -------- buttons
body .mdc-button:not(:disabled) {
  background: rgba(0, 0, 0, 0.05);
  padding: 0 16px;
}

// -------- Mat-Dialog buttons
.mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin: 0 16px;
}
body .mat-mdc-unelevated-button:not(:disabled) {
  background: rgba(0, 0, 0, 0.05);
  // background-color: var(--mdc-filled-button-container-color, transparent);
}

// -------- material table resize column (see ResizeColumnDirective)
.mat-table {
  width: 100%;
  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }
  .mat-cell {
    span {
      //display: block;
      //overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .mat-header-cell {
    position: relative;
    &:not(:last-child) {
      .resize-holder {
        cursor: col-resize;
        width: 20px;
        height: 100%;
        position: absolute;
        right: -10px;
        top: 0;
        z-index: 1;
      }
    }
  }
  .mat-cell,
  .mat-header-cell {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &:not(:nth-child(1)) {
      padding: 0 10px;
    }
  }
}
// end of -------- material table resize column

// -------- custom tooltip for overflow text
.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}
// end of -------- custom tooltip for overflow text

// -------- angular-resizable-element
mwlResizable {
  box-sizing: border-box; // required for the enableGhostResize option to work
}

.resize-handle-top-left:hover,
.resize-handle-top-right:hover,
.resize-handle-bottom-left:hover,
.resize-handle-bottom-right:hover {
  opacity: 0.4;
}

[mwlresizable].resize-active [mwlresizehandle],
.resize-handle-left:hover,
.resize-handle-right:hover,
.resize-handle-top:hover,
.resize-handle-bottom:hover {
  background: rgba(126, 156, 255, 0.24);
}

.resize-handle-top,
.resize-handle-bottom {
  position: absolute;
  height: 5px;
  cursor: row-resize;
  width: 100%;
}

.resize-handle-top-left,
.resize-handle-top-right,
.resize-handle-top {
  top: 0;
}

.resize-handle-bottom-left,
.resize-handle-bottom-right,
.resize-handle-bottom {
  bottom: 0;
}

.resize-handle-left,
.resize-handle-right {
  position: absolute;
  height: 100%;
  cursor: col-resize;
  width: 5px;
}

.resize-handle-top-left,
.resize-handle-bottom-left,
.resize-handle-left {
  left: 0;
}

.resize-handle-top-right,
.resize-handle-bottom-right,
.resize-handle-right {
  right: 0;
}

.resize-handle-top-left,
.resize-handle-top-right,
.resize-handle-bottom-left,
.resize-handle-bottom-right {
  position: absolute;
  height: 10px;
  width: 10px;
  background-image: url(assets/icons/icon-corner-dots.svg);
  opacity: 0.2;
  padding: 5px;
}

.resize-handle-top-left,
.resize-handle-bottom-right {
  cursor: nw-resize;
}

.resize-handle-top-right,
.resize-handle-bottom-left {
  cursor: ne-resize;
}

.resize-handle-top-right {
  transform: rotate(270deg);
  top: 3px;
  right: 3px;
}

.resize-handle-top-left {
  transform: rotate(180deg);
  top: 3px;
  left: 3px;
}

.resize-handle-bottom-right {
  transform: rotate(0deg);
  bottom: 3px;
  right: 3px;
}

.resize-handle-bottom-left {
  transform: rotate(90deg);
  bottom: 3px;
  left: 3px;
}
// end of -------- angular-resizable-element

// --------- simple Grid with rows and cols
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1vh;
  position: relative;

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding-right: 2rem;
  }
  .col-last {
    padding-right: 0;
  }
  .col,
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 1px;
  }
}
// end of --------- simple Grid with rows and cols

.fw-500 {
  font-weight: 500;
}

#matrix-overlay-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

// --------- Business data table context menu

.cell-context-menu {
  .mat-menu-content {
    .mat-menu-item.cdk-program-focused:not([disabled]),
    .mat-menu-item.cdk-keyboard-focused:not([disabled]),
    .mat-menu-item-highlighted:not([disabled]) {
      background: transparent;
    }
    .mat-menu-item:hover:not([disabled]) {
      background: rgba(44, 44, 44, 0.04);
    }
  }
}
// end of --------- Business data table context menu

// AG-Grid: https://www.ag-grid.com/angular-data-grid/getting-started/
@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

@media only screen and (min-width: 1200px) {
  .wide-dialog {
    min-width: 1200px !important;
  }
}
@media only screen and (min-width: 1920px) {
  .wide-dialog {
    min-width: 1600px !important;
  }
}

@media only screen and (min-width: 3840px) {
  .toolbox,
  .right-header,
  .left-header,
  .show-bar,
  .rank-bar {
    margin: 10px 20px 20px 20px;
    height: 128px;
  }

  .bottom-sidebar {
    padding-bottom: 80px;
  }
}

.mat-snack-bar-container.error {
  background-color: var(--warn-darker-color);
  color: var(--text-warn-darker-color);
}

// override cdk-overlay
.cdk-overlay-pane,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  cursor: default;
}
