@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .home-container__menu {
    .mat-mdc-selection-list {
      .mat-mdc-list-item {
        .mdc-list-item__primary-text {
          color: mat.get-color-from-palette($primary-palette, '500-contrast');
        }
      }
      .mat-mdc-list-item[aria-selected*=true] {
        .mdc-list-item__primary-text {
          color: mat.get-color-from-palette($accent-palette, 500);
        }
        background-color: #003040; // mat.get-color-from-palette($primary-palette, 900);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .home-container__menu {
    .mat-mdc-list-base {
      position: relative;
      top: calc(100% / 9);
      padding: 0;
    }
    .mat-mdc-selection-list {
      .mat-mdc-list-item .mdc-list-item__end {
        display: none; // hide radio-buttons
      }
    }
    .mdc-list-item {
      padding-left: calc(45vh / 10.8);
    }
    .mdc-list-item__primary-text {
      // color: white;
      font-family: mat.font-family($typography-config);
      font-style: normal;
      font-weight: 400;
      font-size: calc(24vh / 10.8);
    }
    .mat-mdc-list-item[aria-selected*=true] {
      .mdc-list-item__primary-text {
        font-weight: 800;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
