@font-face {
  font-family: muli-regular;
  src: url("/assets/fonts/Muli-Regular.ttf") format("truetype");
}

@font-face {
  font-family: muli-bold;
  src: url("/assets/fonts/Muli-Bold.ttf") format("truetype");
}

@font-face {
  font-family: muli-light;
  src: url("/assets/fonts/Muli-Light.ttf") format("truetype");
}

@font-face {
  font-family: password-dots;
  src: url("/assets/fonts/pass.ttf") format("truetype");
}