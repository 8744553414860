@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .left-sidebar {
    .mdc-list-item.mdc-list-item--with-one-line[aria-selected*=true] {
      background: rgba(44,44,44,.12);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .left-sidebar {
    .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
      display: none; // hide radio-buttons
    }

    .mdc-list-item--with-trailing-radio.mdc-list-item {
      padding-right: 10px;
    }

    .mat-expansion-panel-header {
      font-family: mat.font-family($typography-config);
      font-size: 15px;
      font-weight: 500;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
