
@use 'sass:map';
@use "@angular/material" as mat;
@use './src/app/components/home/home-list/home-list-component-theme' as home-list-component;
@use './src/app/components/build/left-menu/left-menu-component-theme' as left-menu-component;

@include mat.core();

// $my-primary: mat.define-palette(mat.$teal-palette, 500, 100, 700);
// Setting Primary and Accent color to same value for now
$my-primary: mat.define-palette(mat.$cyan-palette, 500, 100, 700);
$my-accent: mat.define-palette(mat.$cyan-palette, 500, 100, 700);
$my-warn: mat.define-palette(mat.$red-palette, 500, 100, 700);

$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
                warn: $my-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

@include mat.all-component-themes($my-theme);

// --- Application Custom Components
@include home-list-component.theme($my-theme);
@include left-menu-component.theme($my-theme);


// --- Angular Material Theme, Palette list for external CSS
body {
  --light-gray: #d3d3d3;

  --primary-color: #{mat.get-color-from-palette($my-primary, 500)};
  --primary-lighter-color: #{mat.get-color-from-palette($my-primary, 100)};
  --primary-darker-color: #{mat.get-color-from-palette($my-primary, 900)};
  --text-primary-color: #{mat.get-color-from-palette($my-primary, '500-contrast')};
  --text-primary-lighter-color: #{mat.get-color-from-palette($my-primary, '100-contrast')};
  --text-primary-darker-color: #{mat.get-color-from-palette($my-primary, '900-contrast')};

  --accent-color: #{mat.get-color-from-palette($my-accent, 500)};
  --accent-lighter-color: #{mat.get-color-from-palette($my-accent, 100)};
  --accent-darker-color: #{mat.get-color-from-palette($my-accent, 900)};
  --text-accent-color: #{mat.get-color-from-palette($my-accent, '500-contrast')};
  --text-accent-lighter-color: #{mat.get-color-from-palette($my-accent, '100-contrast')};
  --text-accent-darker-color: #{mat.get-color-from-palette($my-accent, '900-contrast')};

  --warn-color: #{mat.get-color-from-palette($my-warn, 500)};
  --warn-lighter-color: #{mat.get-color-from-palette($my-warn, 100)};
  --warn-darker-color: #{mat.get-color-from-palette($my-warn, 900)};
  --text-warn-color: #{mat.get-color-from-palette($my-warn, '500-contrast')};
  --text-warn-lighter-color: #{mat.get-color-from-palette($my-warn, '100-contrast')};
  --text-warn-darker-color: #{mat.get-color-from-palette($my-warn, '900-contrast')};

  --mat-select-color: #fff;
  --mat-select-background-color: #33323d;

  // Override Angular Material check boxes colors
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #{mat.get-color-from-palette($my-accent, '500-contrast')};
  --mdc-checkbox-selected-focus-icon-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-selected-hover-icon-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-selected-icon-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-selected-pressed-icon-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-selected-hover-state-layer-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-selected-pressed-state-layer-color: #{mat.get-color-from-palette($my-accent, 500)};
  --mdc-checkbox-unselected-focus-state-layer-color: #{mat.get-color-from-palette($my-accent, '500-contrast')};
  --mdc-checkbox-unselected-hover-state-layer-color: #{mat.get-color-from-palette($my-accent, '500-contrast')};
  --mdc-checkbox-unselected-pressed-state-layer-color: #{mat.get-color-from-palette($my-accent, '500-contrast')};
}

// --- Override AG-Grid Theme, Palette list for external CSS
body .ag-theme-alpine {
  --ag-alpine-active-color: #2196f3;
  --ag-selected-row-background-color: #{mat.get-color-from-palette($my-accent, 50)}; // rgba(33, 150, 243, 0.3);
  --ag-row-hover-color: rgba(33, 150, 243, 0.1);
  --ag-column-hover-color: rgba(33, 150, 243, 0.1);
  --ag-input-focus-border-color: rgba(33, 150, 243, 0.4);
  --ag-range-selection-background-color: rgba(33, 150, 243, 0.2);
  --ag-range-selection-background-color-2: rgba(33, 150, 243, 0.36);
  --ag-range-selection-background-color-3: rgba(33, 150, 243, 0.49);
  --ag-range-selection-background-color-4: rgba(33, 150, 243, 0.59);
  --ag-background-color: #fff;
  --ag-foreground-color: #181d1f;
  --ag-border-color: #babfc7;
  --ag-secondary-border-color: #dde2eb;
  --ag-header-background-color: #f8f8f8;
  --ag-tooltip-background-color: #f8f8f8;
  --ag-odd-row-background-color: #fcfcfc;
  --ag-control-panel-background-color: #f8f8f8;
  --ag-subheader-background-color: #fff;
  --ag-invalid-color: #e02525;
  --ag-checkbox-unchecked-color: #999;
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-checked-color: var(--ag-alpine-active-color);
  --ag-range-selection-border-color: var(--ag-alpine-active-color);
  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-border-color-invalid: var(--ag-invalid-color);
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);
  --ag-disabled-foreground-color: rgba(24, 29, 31, 0.5);
  --ag-chip-background-color: rgba(24, 29, 31, 0.07);
  --ag-input-disabled-border-color: rgba(186, 191, 199, 0.3);
  --ag-input-disabled-background-color: rgba(186, 191, 199, 0.15);
  --ag-borders: solid 1px;
  --ag-border-radius: 3px;
  --ag-borders-side-button: none;
  --ag-side-button-selected-background-color: transparent;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-height: 30%;
  --ag-grid-size: 6px;
  --ag-icon-size: 16px;
  --ag-row-height: calc(var(--ag-grid-size) * 7);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 4);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-width: 28px;
  --ag-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --ag-font-size: 13px;
  --ag-icon-font-family: agGridAlpine;
  --ag-selected-tab-underline-color: var(--ag-alpine-active-color);
  --ag-selected-tab-underline-width: 2px;
  --ag-selected-tab-underline-transition-speed: 0.3s;
  --ag-tab-min-width: 240px;
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: var(--ag-card-shadow);
  --ag-side-bar-panel-width: 250px;
}

// --- Specific component overrides, pieces that are not in line with the general theming

// Override Angular Material Check boxes
body .mat-mdc-checkbox label {
  white-space: nowrap; // text in one line
}
body .mdc-checkbox__native-control {
  z-index: 1; // bugfix: mat-checkbox doesn't work if click on the checkbox icon
}

// Override mat-menu
body .mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: auto;
}

// Override mat-selector option
body .mat-mdc-option {
  min-height: 36px;
}
body .mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none; // hide default check icon in the right side
}
.cdk-overlay-pane .aggregation-type-selector {
  min-width: 100px;
}

// Mat Wide Dialog
body .mat-mdc-dialog-content {
  max-height: 85vh;
}
body .mat-mdc-tab-body-content {
  overflow: hidden;
}
body .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 10px;
}
body .mat-mdc-dialog-actions {
  justify-content: end;
  flex-direction: row-reverse;
}
body .mat-mdc-dialog-content .tab-content.tab-combine .mat-mdc-text-field-wrapper {
  min-width: 300px;
}

// Override Mat Form Fields
body .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}
body .mdc-text-field--filled.mdc-text-field--focused {
  background-color: whitesmoke;
}


// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto', sans-serif;
  }
}
